import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
import { Grid, Checkbox, FormControlLabel } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState, useCallback } from 'react';

import {
  FormAutocomplete,
  FormTextField,
  Button,
  FormActionWrapper,
  FormRadioGroup,
} from '~common/components';
import { validationSchemas, dataLists } from '~common/utils';
import checkEmailExists from '@patheducation/admin/src/utils/checkEmail.js';
import useStyles from './styles';
import BackButton from '../backButton';
import { formatFieldName } from '../helpers';

const { countryCodeOptions } = dataLists;

// Email regex pattern
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~\-]+(\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~\-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const LearnerAccountPanel = ({
  formData,
  onNextStep,
  submitOnPreviousStep,
  learnerIdxRef,
}) => {
  const { classes } = useStyles();

  const learnerEmailAddresses = formData?.learners.map((learner) => learner.emailAddress);

  const [noContactDetails, setNoContactDetails] = useState(false); // New state for checkbox
  const [emailExists, setEmailExists] = useState(false);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(validationSchemas.learnerAccountPanelSchema),
    context: {
      parentEmailAddress: formData?.emailAddress,
      learnerEmailAddresses,
    },
  });

  const email = useWatch({
    control,
    name: formatFieldName('emailAddress', learnerIdxRef),
  });

  const isValidEmail = (email) => emailRegex.test(email);

  const debouncedCheckEmail = useCallback(
    (() => {
      let timer;
      return (email) => {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          if (email && isValidEmail(email)) {
            try {
              const exists = await checkEmailExists(email);
              setEmailExists(exists);
              if (exists) {
                setError(formatFieldName('emailAddress', learnerIdxRef), {
                  type: 'manual',
                  message: 'This email is already in use',
                });
              } else {
                clearErrors(formatFieldName('emailAddress', learnerIdxRef));
              }
            } catch (error) {
              console.error('Error checking email:', error);
              setError(formatFieldName('emailAddress', learnerIdxRef), {
                type: 'manual',
                message: 'Error checking email availability',
              });
            }
          } else if (email) {
            setError(formatFieldName('emailAddress', learnerIdxRef), {
              type: 'manual',
              message: 'Please enter a valid email address',
            });
          } else {
            clearErrors(formatFieldName('emailAddress', learnerIdxRef));
          }
        }, 200);
      };
    })(),
    [setError, clearErrors],
  );

  useEffect(() => {
    debouncedCheckEmail(email);
  }, [email, debouncedCheckEmail]);

  useEffect(() => {
    if (getValues(formatFieldName('phoneNumberPrefix', learnerIdxRef)) === null) {
      setValue(formatFieldName('phoneNumberPrefix', learnerIdxRef), {
        id: 'UK',
        value: '+44',
        name: 'United Kingdom',
      });
    }
  }, []);

  // Update the form schema when "noContactDetails" changes
  useEffect(() => {
    reset(formData, {
      keepValues: true,
      resolver: yupResolver(validationSchemas.learnerAccountPanelSchema),
    });
  }, [noContactDetails, reset, formData]);

  const handleNoContactDetailsChange = (event) => {
    const isChecked = event.target.checked;
    setNoContactDetails(isChecked);

    if (isChecked) {
      // Clear email and phone number fields when "noContactDetails" is true
      setValue(formatFieldName('emailAddress', learnerIdxRef), '');
      setValue(formatFieldName('noDetails', learnerIdxRef), true);
      setValue(formatFieldName('phoneNumber', learnerIdxRef), 1);
    } else {
      setValue(formatFieldName('noDetails', learnerIdxRef), false);
    }
  };

  const isBackButtonDisabled = getValues(formatFieldName('academicYear', learnerIdxRef)) === null;

  return (
    <form onSubmit={handleSubmit(onNextStep)} className={classes.formPanel} noValidate>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name={formatFieldName('firstName', learnerIdxRef)}
            control={control}
            inputProps={{
              label: 'Learner First Name',
              autoFocus: true,
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name={formatFieldName('lastName', learnerIdxRef)}
            control={control}
            inputProps={{
              label: 'Learner Last Name',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={noContactDetails}
                onChange={handleNoContactDetailsChange}
                color="primary"
              />
            )}
            label="My child does not have contact details"
          />
        </Grid>

        {/* Conditionally render email, phone, and ownAccount fields only if noContactDetails is false */}
        {!noContactDetails && (
          <>
            <Grid item xs={12}>
              <FormTextField
                name={formatFieldName('emailAddress', learnerIdxRef)}
                control={control}
                inputProps={{
                  label: 'Learner Email Address',
                  required: true,
                  className: classes.emailInput,
                }}
                error={!!emailExists}
                helperText={emailExists ? 'This email is already in use' : ''}
              />
            </Grid>
            <Grid item xs={5} sm={4}>
              <FormAutocomplete
                name={formatFieldName('phoneNumberPrefix', learnerIdxRef)}
                control={control}
                className={classes.prefix}
                options={countryCodeOptions}
                disableClearable
                getOptionLabel={(option) => (option ? option.value : '')}
                renderOption={(props, option) => (
                  <li {...props} key={option ? option.id : ''}>
                    {option ? option.name : ''}
                  </li>
                )}
                defaultValue={getValues(formatFieldName('phoneNumberPrefix', learnerIdxRef))}
                inputProps={{
                  placeholder: '+44',
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={7} sm={8}>
              <FormTextField
                name={formatFieldName('phoneNumber', learnerIdxRef)}
                control={control}
                inputProps={{
                  label: 'Number',
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormRadioGroup
                control={control}
                name={formatFieldName('ownAccount', learnerIdxRef)}
                label="Would you like the student to have access to their own account using their own log in details?"
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <FormActionWrapper>
            <BackButton
              onClick={submitOnPreviousStep}
              disabled={isBackButtonDisabled}
            />
            <Button
              type="submit"
              disabled={
                !noContactDetails && (emailExists || !isValidEmail(email))
              }
            >
              Next
            </Button>
          </FormActionWrapper>
        </Grid>
      </Grid>
    </form>
  );
};

LearnerAccountPanel.propTypes = {
  formData: PropTypes.shape({
    emailAddress: PropTypes.string.isRequired,
    learners: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  onNextStep: PropTypes.func.isRequired,
  submitOnPreviousStep: PropTypes.func.isRequired,
  learnerIdxRef: PropTypes.shape({
    current: PropTypes.number.isRequired,
  }).isRequired,
};

export default LearnerAccountPanel;
