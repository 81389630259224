import PropTypes from 'prop-types';
import { FormTitle } from '~common/components';
import { config } from '~common/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import AccountPanel from '../../components/registrationForm/accountPanel';
import AddressPanel from '../../components/registrationForm/addressPanel';
import LearnerAccountPanel from '../../components/registrationForm/learnerAccountPanel';
import ReviewPanel from '../../components/registrationForm/reviewPanel';
import ConfirmationPanel from '../../components/registrationForm/confirmationPanel';
import RegistrationForm from '../../components/registrationForm';
import useSignUpForm from '../../components/registrationForm/hooks/useSignUpForm';
import formFields from '../../utils/registerLearnerFields.json';
import LearnerInfoPanel from '../../components/registrationForm/learnerInfoPanel';

const learnerPanelAmount = 2;

export const getDescription = (formStep, learnerIdxRef) => {
  if ((formStep === 0) || (formStep === 1)) return 'Parent or Guardian details';
  if (formStep < 4 + (learnerIdxRef.current * learnerPanelAmount)) return 'Learner details';
  if (formStep === 4 + (learnerIdxRef.current * learnerPanelAmount)) return 'Review Agreement';
  if (formStep === 5 + (learnerIdxRef.current * learnerPanelAmount)) return '';
  return 'Parent or Guardian details';
};

export const getImagePath = (formStep, learnerIdxRef) => {
  if ((formStep === 0) || (formStep === 1)) return 'url("/student.png")';
  if (formStep % 2 === 0 && formStep < 4 + (learnerIdxRef.current * learnerPanelAmount)) return 'url("/student.png")';
  if (formStep % 2 === 1 && formStep < 4 + (learnerIdxRef.current * learnerPanelAmount)) return 'url("/writing.png")';
  if (formStep === 4 + (learnerIdxRef.current * learnerPanelAmount)) return 'url("/writing2.png")';
  return 'url("/student.png")';
};

const RegisterLearner = ({ defaultFormStep, overwriteUser }) => {
  const {
    formData,
    formStep,
    onNextStep,
    onPrevStep,
    submitOnPreviousStep,
    onSubmitBasic,
    onSubmitCompleteRegister,
    onAddAnotherLearner,
    error,
    loading,
    learnerIdxRef,
  } = useSignUpForm({
    defaultFormStep,
    overwriteUser,
    formFields: {
      ...formFields,
      agreementVersion: config.parentalAgreementVersion,
    },
  });

  const navigate = useNavigate();
  const loc = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(loc.search);
    console.log(params);
    if (params.get('event')) {
      navigate(`/agreement?event=${params.get('event')}`);
    }
  }, []);

  const conditionalComponent = () => {
    // First Few Panels
    if (formStep === 0) {
      return (
        <AccountPanel
          formData={formData}
          onNextStep={onNextStep}
          onSubmit={onSubmitBasic}
        />
      );
    }
    if (formStep === 1) {
      return (
        <ConfirmationPanel />
      );
    }
    if (formStep === 2) {
      return (
        <AddressPanel
          formData={formData}
          onNextStep={onNextStep}
          onPrevStep={onPrevStep}
          userType="learner"
        />
      );
    }

    // Multiple Learners Loop
    for (let i = 0; i < learnerIdxRef.current + 1; i += 1) {
      if (formStep === 3 + (i * learnerPanelAmount)) {
        return (
          <LearnerAccountPanel
            formData={formData}
            onNextStep={onNextStep}
            submitOnPreviousStep={onPrevStep}
            learnerIdxRef={{ current: i }}
          />
        );
      }
      if (formStep === 4 + (i * learnerPanelAmount)) {
        return (
          <LearnerInfoPanel
            isLastLearner={i === learnerIdxRef.current}
            formData={formData}
            onNextStep={onNextStep}
            submitOnPreviousStep={onPrevStep}
            onAddAnotherLearner={onAddAnotherLearner}
            learnerIdxRef={{ current: i }}
          />
        );
      }
    }

    // Ending
    if (formStep === 5 + (learnerIdxRef.current * learnerPanelAmount)) {
      return (
        <ReviewPanel
          formData={formData}
          onSubmit={onSubmitCompleteRegister}
          onPrevStep={onPrevStep}
          error={error}
          loading={loading}
          userType="learner"
        />
      );
    }
    return (
      <AccountPanel
        formData={formData}
        onNextStep={onNextStep}
        onSubmit={onSubmitBasic}
      />
    );
  };

  return (
    <RegistrationForm imagePath={getImagePath(formStep, learnerIdxRef)}>
      <FormTitle
        title="Sign up for a learner account"
        description={getDescription(formStep, learnerIdxRef)}
        image="/logo.svg"
      />
      {conditionalComponent()}
    </RegistrationForm>
  );
};

RegisterLearner.propTypes = {
  defaultFormStep: PropTypes.number,
  overwriteUser: PropTypes.shape({}),
};

RegisterLearner.defaultProps = {
  defaultFormStep: 0,
  overwriteUser: {},
};

export default RegisterLearner;
